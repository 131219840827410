<template>
  <div
    id="properties_app"
    class="my-lists-main-wrapper back-white"
    style="position: relative"
  >
    <theme-style wrapper-prefix=".my-lists-main-wrapper" />
    <PropertiesEmbeddedComponent />
  </div>
</template>

<script>
import ThemeStyle from "@/components/ThemeStyle";
import PropertiesEmbeddedComponent from "@/components/EmbeddedTable/PropertiesEmbeddedComponent";

export default {
  name: "properties_embeded_table",
  components: { ThemeStyle, PropertiesEmbeddedComponent },
  props: { activeTab: String },
  async beforeCreate() {
    await this.$store.dispatch("Agent/loadAgentData", null, { root: true });
  },
};
</script>

<style lang="scss">
.my-lists-main-wrapper,
.modal {
  @import "./scss/custom.scss";
}

@font-face {
  font-family: "Metro-Sans";
  src: url("/fonts/my-properties-widget/Metro-Sans-Regular.eot");
  src: url("/fonts/my-properties-widget/Metro-Sans-Regular.eot")
      format("embedded-opentype"),
    url("/fonts/my-properties-widget/Metro-Sans-Regular.ttf") format("truetype"),
    url("/fonts/my-properties-widget/Metro-Sans-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
</style>
<style>
#properties_app {
  font-family: "Metro-Sans", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: var(--BodyTextColor, #2c3e50);
  margin-top: 0px;
}

html {
  font-size: 16px;
}

.my-lists-main-wrapper .jumbotron,
.modal .jumbotron {
  background: transparent;
}

.modal {
  opacity: 1 !important;
}

.roboto-font {
  font-family: "Roboto", sans-serif !important;
}

.bottom-0 {
  bottom: 0;
}

.my-lists-main-wrapper .ml-as-table .table th {
  padding: 0.3rem 0.75rem;
  background: #525151;
  color: #ffffff;
  font-size: 17px;
}

.my-lists-main-wrapper .modal-content,
.modal .modal-content {
  border-radius: 0;
}

.mp-additional-info .modal-dialog.modal-md {
  max-width: 570px;
}

.my-lists-main-wrapper .modal-dialog,
.modal .modal-dialog {
  top: 50%;
  transform: translateY(-50%) !important;
  margin-top: 0 !important;
  opacity: 1 !important;
}

.back-white {
  background: #fff;
}
</style>
