<template>
  <div
    :class="!activeList.length ? 'no-data-bg' : ''"
    class="main-container"
    style="height: 508px"
  >
    <div v-if="agentLoading || loading" style="width: 100%; height: 100%">
      <b-spinner class="spinner" v-if="agentLoading || loading" />
    </div>
    <div v-show="activeList.length < 4" :class="defineClasses">
      <h3 class="title-info">Promote Yourself</h3>
      <div
        :class="
          activeList.length === 3
            ? 'holder-subtitle-streched'
            : 'holder-subtitle'
        "
      >
        <div
          :class="
            activeList.length === 3 ? 'title-body-three' : 'title-body-two'
          "
        >
          Start building your brand with flyers, brochures, post cards, door
          hangers, and business cards to generate buzz in whatever neighborhood
          you’re targeting!
        </div>
        <b-button
          variant="primary"
          class="px-3 py-2 font-weight-bold btn-browse"
          href="/Store"
          >Browse</b-button
        >
      </div>
    </div>

    <PropertiesTable
      :list="activeList"
      v-if="!agentLoading && !loading && activeList.length"
    />

    <NoData v-if="!agentLoading && !loading && !activeList.length" />
  </div>
</template>

<script>
import PropertiesTable from "./PropertiesTable";
import NoData from "./NoData";
import { mapState } from "vuex";

const ITEMS_ON_WIDGET = 4;

export default {
  name: "PropertiesEmbeddedComponent",
  components: { PropertiesTable, NoData },
  computed: {
    ...mapState({
      loading: (state) => state.Lists.loading,
      lists: (state) => state.Lists.lists,
      agentLoading: (state) => state.Agent.loading,
      agent: (state) => state.Agent.agent,
      agentId: (state) => state.Agent.agentId,
    }),
    activeList() {
      if (!this.lists) {
        return [];
      }
      const filteredList = this.lists.filter(
        ({ is_archive: isArchive = false }) => !isArchive
      );

      return filteredList.slice(0, ITEMS_ON_WIDGET);
    },
    defineClasses() {
      console.log("Number of active lists", this.activeList.length);
      if (this.activeList.length === 1) {
        return "class-one";
      }
      if (this.activeList.length === 2) return "class-two";
      if (this.activeList.length === 3) return "class-three";
      if (this.activeList.length === 4) return "class-four";
      return "class-none";
    },
  },
  watch: {
    agentId(newAgentId) {
      if (newAgentId) {
        this.$store.dispatch("Lists/loadListsByAgentId", null, { root: true });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.no-data-bg {
  background-image: url("../../assets/shutterstock_6294729621.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.main-container {
  background-image: url("../../assets/shutterstock_6294729621.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
}

.class-one {
  background: white;
  width: 333px;
  height: 294px;
  margin: 50px;
  padding: 33px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.title-info {
  /* H6 Paragraph Heading */

  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */

  display: flex;
  align-items: center;

  /* primary_text */

  color: var(--BodyTextColor, #000f0c);
}
.holder-subtitle {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.holder-subtitle-streched {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  overflow: hidden;
}
.title-body-three {
  text-align: left;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: var(--BodyTextColor, #000f0c);
  flex: none;
  align-self: stretch;
  margin-bottom: 25px;
  margin-right: 0;
  margin-top: 10px;
  flex-wrap: wrap;
  width: 90%;
  padding-left: 12px;
  padding-right: 0px;
}
.holder-subtitle-streched p {
  text-align: left;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}
.title-body-three {
  text-align: left;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: var(--BodyTextColor, #000f0c);
  flex: none;
  align-self: stretch;
  margin-bottom: 15px;
  margin-right: 10px;
  margin-top: 10px;
  flex-wrap: wrap;
  width: 90%;
  padding-left: 12px;
  padding-right: 45px;
}
.title-body-two {
  text-align: left;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: var(--BodyTextColor, #000f0c);
  flex: none;
  align-self: stretch;
  margin-bottom: 15px;
  margin-right: 10px;
  margin-top: 10px;
  flex-wrap: wrap;
  width: 90%;
}
.holder-subtitle-streched p {
  text-align: left;
  font-family: "Metro Sans";
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}
.class-two {
  width: 100%;
  height: 254px;
  background: white;
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.class-three {
  width: 100%;
  height: 127px;
  background: white;
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border: 1px solid rgba(0, 0, 0, 0.125);
  overflow: hidden;
}
.class-none {
  display: none;
}

.btn-browse {
  background-color: var(--ButtonBackground, rgba(82, 81, 81, 1));
  border-color: var(--ButtonBackground, rgba(82, 81, 81, 1));
}
</style>
